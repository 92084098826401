







import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'Skeleton',
})
export default class Skeleton extends Vue {
  @Prop({ default: '1rem', type: String }) public height!: string
  @Prop({ default: '100%', type: String }) public width!: string

  get skeletonStyles(): {
    height: string
    width: string
    borderRadius: string
  } {
    return {
      height: this.height,
      width: this.width,
      borderRadius: '0.25rem',
    }
  }
}
