var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"overflow-x-auto",attrs:{"data-testid":"global-component-table-v2"}},[_c('div',{staticClass:"table table-container"},[_c('div',{staticClass:"thead",attrs:{"data-testid":"global-component-table-v2__head"}},_vm._l((_vm.headers),function(header,idx){return _c('div',{key:idx,staticClass:"th",style:(Object.assign({}, {minWidth: header.width}, header.customStyle)),attrs:{"data-testid":("global-component-table-v2__head__" + idx)}},[(_vm.hasHeaderSlot(_vm.customName, idx))?_vm._t(_vm.hasHeaderName(_vm.customName, idx),null,{"id":header[idx],"index":("header-" + idx),"data":header}):(typeof header === 'object')?_c('div',{staticClass:"w-full text-sm text-gray-4 flex flex-row",class:[{'cursor-pointer': header.withSort}],attrs:{"data-testid":"global-component-table-v2__header-title"},on:{"click":function () { return _vm.onSort(header.sortName); }}},[_c('div',[_c('span',{staticClass:"sort-field"},[_vm._v(_vm._s(header.title))]),(_vm.requiredValue.includes(header.title))?_c('span',{staticClass:"text-bgPrimary text-sm ml-0.5"},[_vm._v("*")]):_vm._e()]),(header.withSort)?_c('div',{staticClass:"flex flex-col table-sort",style:(Object.assign({}, header.sortStyle))},[_c('div',{staticClass:"ascending-icon",class:{
                'selected-sort': _vm.isSelectedSort(header.sortName, 'ASC'),
              }},[_c('AscendingIcon')],1),_c('div',{staticClass:"descending-icon",class:{
                'selected-sort': _vm.isSelectedSort(header.sortName, 'DESC'),
              }},[_c('AscendingIcon')],1)]):_vm._e()]):_c('div',{staticClass:"text-sm text-gray-4",attrs:{"data-testid":"global-component-table-v2__header-title"}},[_c('span',[_vm._v(_vm._s(header))]),(_vm.requiredValue.includes(header.title))?_c('span',{staticClass:"text-bgPrimary text-sm ml-0.5"},[_vm._v("*")]):_vm._e()])],2)}),0),(_vm.isLoading)?_c('div',{staticClass:"loading",attrs:{"data-testid":"global-component-table-v2__loader"}},[(_vm.isHasSlot('loading'))?_vm._t("loading"):[_c('div',{staticClass:"tbody"},_vm._l((10),function(number){return _c('div',{key:("loading-" + number),staticClass:"rows"},[_c('div',{staticClass:"tr"},_vm._l((_vm.headers),function(header,index){return _c('div',{key:("skeleton-" + index),staticClass:"td",staticStyle:{"width":"auto"}},[_c('Skeleton',{attrs:{"width":header.width || header.customStyle.maxWidth,"height":header.height || header.customStyle.maxHeight}})],1)}),0)])}),0)]],2):(!_vm.isLoading && _vm.dataItems.length !== 0)?_c('div',{staticClass:"tbody",attrs:{"data-testid":"global-component-table-v2__body"}},_vm._l((_vm.dataItems),function(data,index){return _c('div',{key:index,staticClass:"rows"},[(!Array.isArray(data[0]))?_c('div',{staticClass:"tr"},[_vm._l((data),function(item,idx){return _c('div',{key:idx,class:['td',
              { hide: _vm.hideIndex.includes(idx) },
              _vm.customClassTd
            ],style:(_vm.getStyleItem(item))},[(_vm.hasSlot(_vm.customName, idx))?_vm._t(_vm.hasName(_vm.customName, idx),null,{"id":data[_vm.idIndex],"index":index,"indexField":idx,"data":item}):(typeof item === 'object')?_c('div',{staticClass:"w-full text-sm text-gray-4"},[_vm._v(" "+_vm._s(item.value)+" ")]):_c('div',{staticClass:"w-full text-sm text-gray-4"},[_vm._v(_vm._s(item))])],2)}),(_vm.isHasAction)?_c('div',{staticClass:"td"},[(Boolean(_vm.$slots['action']))?_vm._t("action",null,{"id":data[_vm.idIndex]}):_c('div',{staticClass:"text-primary font-semibold"},[_vm._v("View Detail")])],2):_vm._e()],2):_c('div',_vm._l((data),function(itemRow,idz){return _c('div',{key:idz,staticClass:"tr",staticStyle:{"padding-bottom":".5rem","padding-top":"1rem"}},[_vm._l((itemRow),function(item,idx){return _c('div',{key:idx,class:['td',
              { hide: _vm.hideIndex.includes(idx), 'border-t ': idx !== 0  && idz > 0, 'h-20 mb-0': idz > 0},
              _vm.customClassTd
            ],style:(_vm.getStyleItem(item))},[(_vm.hasSlot(_vm.customName, idx))?_vm._t(_vm.hasName(_vm.customName, idx),null,{"id":data[_vm.idIndex],"index":index,"indexField":idx,"data":item}):(typeof item === 'object')?_c('div',{staticClass:"w-full text-sm text-gray-4"},[_vm._v(" "+_vm._s(item.value)+" ")]):_c('div',{staticClass:"w-full text-sm text-gray-4"},[_vm._v(_vm._s(item))])],2)}),(_vm.isHasAction)?_c('div',{staticClass:"td"},[(Boolean(_vm.$slots['action']))?_vm._t("action",null,{"id":data[_vm.idIndex]}):_c('div',{staticClass:"text-primary font-semibold"},[_vm._v("View Detail")])],2):_vm._e()],2)}),0),(_vm.isHasSlot('body-row'))?_vm._t("body-row",null,{"data":index}):_vm._e(),(_vm.listHasCollapse[index] || _vm.isHasCollapse)?_c('div',{staticClass:"board-expand-collapse",attrs:{"data-testid":"global-component-table-v2__expand-collapse"}},[_c('div',{staticClass:"header-expand-collapse"},[_c('span',{staticClass:"title-expand-collapse"},[_vm._v(_vm._s(_vm.titleCollapse))]),_c('div',{class:[
                'button-expand-collapse',
                { isExpand: _vm.listExpanded.includes(data[_vm.idIndex] || index) } ],attrs:{"id":(_vm.idBtnExpand + "-" + index),"data-testid":"global-component-table-v2__expand-collapse__event-onExpand","data-testid-value":data.id},on:{"click":function () { return _vm.onExpand(data, data[_vm.idIndex] || index, index); }}},[_c('expand-icon',{attrs:{"fill":"#333333"}})],1)]),(_vm.listExpanded.includes(data[_vm.idIndex] || index))?_c('div',{staticClass:"content-expand-collapse"},[(_vm.isHasSlot('expand-collapse'))?_vm._t("expand-collapse",null,{"id":data[_vm.idIndex] || data[index],"index":index}):_c('div',[_vm._v("Description")])],2):_vm._e()]):_vm._e()],2)}),0):_c('div',{staticClass:"empty-state",attrs:{"data-testid":"global-component-table-v2__empty-state"}},[(_vm.isHasSlot('data-empty'))?_vm._t("data-empty"):_c('div',{staticClass:"w-full text-center py-10 text-gray-1"},[_vm._v("Data is empty")])],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }